/*
* @description 业务 store
*/
export const state = () => ({
    cryList: [
        {
            "afterAddCartStyle": 4,
            "countryCode": "AU",
            "currencyCode": "AUD",
            "currencySymbol": "AU$",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Australia",
            "fillingOrder": 1,
            "id": "16345633730482siELlJS",
            "includingTaxShow": true,
            "language": "en_US",
            "mRealmName": "https://au.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/16347874280509f5bb8.png",
            "pcRealmName": "https://au.yfn.com",
            "showCustomizePage": false,
            "showName": "Australia",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 1,
            "countryCode": "BR",
            "currencyCode": "BRL",
            "currencySymbol": "R$",
            "dateFormatType": "dd/MM/yy hh:mm:ss",
            "englishName": "Brazil",
            "fillingOrder": 1,
            "id": "16345633730595zumjzDl",
            "includingTaxShow": true,
            "language": "pt_PT",
            "mRealmName": "https://br.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/163478745818421e10a.png",
            "pcRealmName": "https://br.yfn.com",
            "showCustomizePage": false,
            "showName": "Brazil",
            "switchTag": true,
            "vestRealmName": "https://api.yfn.com"
        },
        {
            "afterAddCartStyle": 4,
            "countryCode": "CA",
            "currencyCode": "CAD",
            "currencySymbol": "CA$",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Canada",
            "fillingOrder": 1,
            "id": "1634563373063Kjbxc0Yl",
            "includingTaxShow": true,
            "language": "en_US",
            "mRealmName": "https://ca.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787475509d9a00c.png",
            "pcRealmName": "https://ca.yfn.com",
            "showCustomizePage": false,
            "showName": "Canada",
            "switchTag": true,
            "vestRealmName": "https://api.yfn.com"
        },
        {
            "afterAddCartStyle": 5,
            "countryCode": "FR",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "dd/MM/yy hh:mm:ss",
            "englishName": "France",
            "fillingOrder": 1,
            "id": "1634563373102Q9pV9697",
            "includingTaxShow": true,
            "language": "fr_FR",
            "mRealmName": "https://www.yfn.fr",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/16347875955036f4e4c.png",
            "pcRealmName": "https://www.yfn.fr",
            "showCustomizePage": false,
            "showName": "France",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 4,
            "countryCode": "DE",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Germany",
            "fillingOrder": 1,
            "id": "1634563373077Laaa6JEH",
            "includingTaxShow": true,
            "language": "de_DE",
            "mRealmName": "https://www.yfn.de",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787507889d6837d.png",
            "pcRealmName": "https://www.yfn.de",
            "showCustomizePage": false,
            "showName": "Germany",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 3,
            "countryCode": "IT",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Italy",
            "fillingOrder": 1,
            "id": "1634563373126AGd71n1e",
            "includingTaxShow": true,
            "language": "it_IT",
            "mRealmName": "https://it.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787785786c61d66.png",
            "pcRealmName": "https://it.yfn.com",
            "showCustomizePage": false,
            "showName": "Italy",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 1,
            "countryCode": "LU",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "yyyy/MM/dd hh:mm:ss",
            "englishName": "Luxembourg",
            "fillingOrder": 1,
            "id": "16345633731476l1SS8T6",
            "includingTaxShow": true,
            "language": "en_US",
            "mRealmName": "https://lu.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/163478786072660c367.png",
            "pcRealmName": "https://www.yfn.com",
            "showCustomizePage": false,
            "showName": "Luxembourg",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 1,
            "countryCode": "MX",
            "currencyCode": "MXN",
            "currencySymbol": "$MXN",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Mexico",
            "fillingOrder": 1,
            "id": "1634563373156DByoIlKs",
            "includingTaxShow": true,
            "language": "es_ES",
            "mRealmName": "https://www.yfn.mx",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787884485bde194.png",
            "pcRealmName": "https://www.yfn.mx",
            "showCustomizePage": false,
            "showName": "Mexico",
            "switchTag": true,
            "vestRealmName": "https://api.yfn.com"
        },
        {
            "afterAddCartStyle": 3,
            "countryCode": "NL",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Netherlands",
            "fillingOrder": 1,
            "id": "1634563373164iHYj3aEb",
            "includingTaxShow": true,
            "language": "nl_NL",
            "mRealmName": "https://nl.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787917028b984d1.png",
            "pcRealmName": "https://nl.yfn.com",
            "showCustomizePage": false,
            "showName": "Netherlands",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 3,
            "countryCode": "PL",
            "currencyCode": "PLN",
            "currencySymbol": "zł",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Poland",
            "fillingOrder": 1,
            "id": "16345633731770qfjf3zM",
            "includingTaxShow": true,
            "language": "pl_PL",
            "mRealmName": "https://www.yfn.pl",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787971172a01697.png",
            "pcRealmName": "https://www.yfn.pl",
            "showCustomizePage": false,
            "showName": "Poland",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 1,
            "countryCode": "PT",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Portugal",
            "fillingOrder": 1,
            "id": "16345633731808c6nIRQk",
            "includingTaxShow": true,
            "language": "pt_PT",
            "mRealmName": "https://pt.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787992374e8064f.png",
            "pcRealmName": "https://pt.yfn.com",
            "showCustomizePage": false,
            "showName": "Portugal",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 1,
            "countryCode": "ES",
            "currencyCode": "EUR",
            "currencySymbol": "€",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "Spain",
            "fillingOrder": 1,
            "id": "1634563373092zIr0Vobu",
            "includingTaxShow": true,
            "language": "es_ES",
            "mRealmName": "https://www.yfn.es",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634787567220dbafba.png",
            "pcRealmName": "https://www.yfn.es",
            "showCustomizePage": false,
            "showName": "Spain",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 4,
            "countryCode": "UK",
            "currencyCode": "GBP",
            "currencySymbol": "£",
            "dateFormatType": "dd/MM/yyyy hh:mm:ss",
            "englishName": "United Kingdom",
            "fillingOrder": 1,
            "id": "1634563373205idfcxbBC",
            "includingTaxShow": true,
            "language": "en_US",
            "mRealmName": "https://www.yfn.uk",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634788129075c9bf43.png",
            "pcRealmName": "https://www.yfn.uk",
            "showCustomizePage": false,
            "showName": "United Kingdom",
            "switchTag": true,
            "vestRealmName": ""
        },
        {
            "afterAddCartStyle": 4,
            "countryCode": "US",
            "currencyCode": "USD",
            "currencySymbol": "$",
            "dateFormatType": "MM/dd/yyyy hh:mm:ss",
            "englishName": "United States",
            "fillingOrder": 1,
            "id": "1634563373209bVSDglQE",
            "includingTaxShow": true,
            "language": "en_US",
            "mRealmName": "https://www.yfn.com",
            "nationalFlagUrl": "https://cdn2.selleroa.com/yfni/test/1634788137839d3b96e.png",
            "pcRealmName": "https://www.yfn.com",
            "showCustomizePage": false,
            "showName": "United States",
            "switchTag": true,
            "vestRealmName": ""
        }
    ], // 是否刷新页面
})

export const mutations = {}

export const actions = {}

export const getters = {}