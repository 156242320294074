/**
 * @description google、facebook、awin等三方数据埋点
**/
const send = {
    // fbq
    fbq: (params, ctx) => {
        ctx.$api.mixins.sendFb({
            data: JSON.stringify(params)
        }).then(response => {})
    }
};
class Fn {
    klaviyo(options, ctx){
        try {
            const page_host = window.location.host;
            if(!window.location.href.includes('yfn.com')) return
            if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) return
            !function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}()
            switch (options.type) {
                case 'ViewedProduct': //浏览商品
                    klaviyo.push(["track", "Viewed Product", options.params]);
                    break;
                case 'trackViewedItem': //浏览商品
                    klaviyo.push(["trackViewedItem", options.params]);
                    break;
                case 'addToCart': //加购后购物车触发
                    ctx.$api.bag.getBag({id:'',onSelect:false}).then(res=>{
                        let baginfo = res.result
                        let params = {};
                        let items = [];
                        let itemName = [];
                        baginfo?.shopCart?.map(itemlist=>{
                            itemlist.cartItems.map(item=>{
                                items.push({
                                    "ProductID": item.productCode,
                                    "SKU": item.sellerSku||item.productSellerSku,
                                    "ProductName": item.productName,
                                    "Quantity": item.quantity,
                                    "ItemPrice": Number(item.displaySkuPrice),
                                    "RowTotal": Number((Number(item.displaySkuPrice)*item.quantity).toFixed(2)),
                                    "ProductURL": `${window.location.origin}/${item.seoUrl}`,
                                    "ImageURL": item.skuPic,
                                    "ProductCategories": item?.backCat?.path?.split('/')||[]
                                })
                                itemName.push(item.productName)
                            })
                        })
                        params = {
                            ...options.params,
                            $value: Number(baginfo.cartAmountDetail.displayCartTotalAmt) || 0,
                            ItemNames: itemName,
                            CheckoutURL: `${window.location.origin}/shoppingbag`,
                            Items:items
                        }
                        klaviyo.push(["track", "Added to Cart", params]);
                    })
                    break;
                case 'checkout': //开始结账
                    klaviyo.push(["track", "Started Checkout", options.params]);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error)
        }
    }
    // gtag 埋点
    gtag(options) {
        try {
            const page_host = window.location.host;
            if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) return
            switch(options.type) {
                case 'user_data':
                    gtag('set', 'user_data', options.params);
                    break;
                case 'conversion_cart':
                    this.gtagConversionCart(options)
                    break;
                case 'conversion_checkout':
                    this.gtagConversionCheckout(options)
                    break;
                case 'conversion_purchase':
                    this.gtagConversionPurchase(options)
                    break;
                case 'add_shipping_info':
                    gtag('event', 'add_shipping_info', options.params);
                    break;
                case 'add_payment_info':
                    gtag('event', 'add_payment_info', options.params);
                    break;
                case 'add_to_cart':
                    gtag('event', 'add_to_cart', options.params);
                    break;
                case 'begin_checkout':
                    gtag('event', 'begin_checkout', options.params);
                    break;
                case 'purchase':
                    gtag('event', 'purchase', options.params);
                    break;
                case 'view_item':
                    gtag('event', 'view_item', options.params);
                    break;
                case 'add_to_wishlist':
                    gtag('event', 'add_to_wishlist', options.params);
                    break;
                case 'view_cart':
                    gtag('event', 'view_cart', options.params);
                    break;
                case 'remove_from_cart':
                    gtag('event', 'remove_from_cart', options.params);
                    break;
                case 'view_item_list':
                    gtag('event', 'view_item_list', options.params);
                    break;
                case 'login':
                    gtag('event', 'login', options.params);
                    break;
                case 'sign_up':
                    gtag('event', 'sign_up', options.params);
                    break;
                case 'search':
                    gtag('event', 'search', options.params);
                    break;
                case 'select_item':
                    gtag('event', 'select_item', options.params);
                    break;
                case 'share':
                    gtag('event', 'share', options.params);
                    break;
                default:
            }
        } catch (error) {
            console.log(error)
        }
    }
    gtagConversionCart(options) { // gtag 加购账户
        try {
            const page_host = location.host;
            gtag('event', 'conversion', {
                'send_to': 'AW-325523672/zZ6CCPmZs_ACENixnJsB',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-10875064895/FRquCPPK_LIDEL-k0cEo',
                ...options.params
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-10885927993/UO1vCMSQ8LQDELmo6MYo',
                ...options.params
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-11157838943/nxTqCL7vk5wYEN-4vMgp',
                ...options.params
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-11157773323/vs9xCOmSppwYEIu4uMgp',
                ...options.params
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-11157837200/FHfiCKSoj5wYEJCrvMgp',
                ...options.params
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-11157773599/jX8wCMjhjJwYEJ-6uMgp',
                ...options.params
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-11127768621/L8eZCN2-vq4YEK2Mkbop',
                ...options.params
            });
            if(page_host.includes('au.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209454013/CMYCCPeKh6kYEL3jiuEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227339976/2Xb7CLPbqq4YEMi5zukp',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.uk')) {
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11209475132/cQJiCJ6dh6kYELyIjOEp',
                //     ...options.params
                // });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128002358/whmaCLjdqq4YELaun7op',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.de')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209464365/WaX8CIXhh6kYEK20i-Ep',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128041469/ms2cCPXUv64YEP3fobop',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128140797/eT_MCND8os8YEP3np7op',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.fr')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209494545/LYfYCKyXgKkYEJGgjeEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227223104/Zy-DCM_9qq4YEMCox-kp',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.es')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209466507/AsjPCPyIhKkYEIvFi-Ep',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128018717/7I1qCKC4v64YEJ2uoLop',
                    ...options.params
                });
            }
            if(page_host.includes('pt.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209437223/iD1HCOvri6kYEKfgieEp',
                    ...options.params
                });
            }
            if(page_host.includes('nz.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11211816115/GGulCPaRjKkYELP5muIp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11213940102/yXF-CK-Sta4YEIbLnOMp',
                    ...options.params
                });
            }
            if(page_host.includes('ca.yfn')) {
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11211868232/8JXpCLm3hKkYEMiQnuIp',
                //     ...options.params
                // });
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11213935986/0NsGCM2Evq4YEPKqnOMp',
                //     ...options.params
                // });
                //广告数据加入dataLayer
                window.dataLayer?.push({
                    'ads_tid': '', //订单id
                    'ads_value': options.params.value, //价格
                    'ads_currency': options.params.currency //价格单位
                });
                //GTM广告事件调用
                gtag('event', 'ads-add_to_cart');
            }
            if(page_host.includes('yfn.ae')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209451613/GkdKCJqGjKkYEN3QiuEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227340735/oDT6CIKHq64YEL-_zukp',
                    ...options.params
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    gtagConversionCheckout(options) { // gtag 结账账户
        try {
            const page_host = location.host;
            gtag('event', 'conversion', {
                'send_to': 'AW-325523672/US8wCPCKw5EYENixnJsB',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-10885927993/IhWECLjHw5EYELmo6MYo',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-10875064895/dD_lCKL8ybIDEL-k0cEo',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157838943/uHGtCLvvk5wYEN-4vMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157773323/cQ_DCOySppwYEIu4uMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157837200/mKSaCL-rj5wYEJCrvMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157773599/AqnRCMvhjJwYEJ-6uMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11127768621/SWwRCOC-vq4YEK2Mkbop',
                ...options.params
            })
            if(page_host.includes('au.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209454013/ICieCPqKh6kYEL3jiuEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227339976/uikaCLbbqq4YEMi5zukp',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.uk')) {
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11209475132/L3xaCOPqkakYELyIjOEp',
                //     ...options.params
                // });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128002358/wTjtCLiEvq4YELaun7op',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.de')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209464365/GBChCNTuh6kYEK20i-Ep',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128041469/kY5jCIrev64YEP3fobop',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128140797/ALHJCOuRqs8YEP3np7op',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.fr')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209494545/Qsc4CJiYgKkYEJGgjeEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227223104/QK_RCNL9qq4YEMCox-kp',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.es')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209466507/8ONPCPaElqkYEIvFi-Ep',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128018717/bw-UCP6-tq4YEJ2uoLop',
                    ...options.params
                });
            }
            if(page_host.includes('pt.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209437223/wYONCNyqhKkYEKfgieEp',
                    ...options.params
                });
            }
            if(page_host.includes('nz.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11211816115/gPtdCPmRjKkYELP5muIp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11213940102/TQA7CLKSta4YEIbLnOMp',
                    ...options.params
                });
            }
            if(page_host.includes('ca.yfn')) {
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11211868232/54ZwCLy3hKkYEMiQnuIp',
                //     ...options.params
                // });
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11213935986/KtYBCMqEvq4YEPKqnOMp',
                //     ...options.params
                // });
                //广告数据加入dataLayer
                window.dataLayer?.push({
                    'ads_tid': '', //订单id
                    'ads_value': options.params.value, //价格
                    'ads_currency': options.params.currency //价格单位
                });
                //GTM广告事件调用
                gtag('event', 'ads-checkout');
            }
            if(page_host.includes('yfn.ae')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209451613/PLE1COu7lqkYEN3QiuEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227340735/eId-CIWHq64YEL-_zukp',
                    ...options.params
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    gtagConversionPurchase(options) { // gtag 购买账户
        try {
            const page_host = location.host;
            gtag('event', 'conversion', {
                'send_to': 'AW-325523672/_nljCLeNs_ACENixnJsB',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-10885927993/6Sz5CNnivLQDELmo6MYo',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-10875064895/hHtzCOi_jK4DEL-k0cEo',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157838943/4ujYCMuChJwYEN-4vMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157773323/1H0cCNvHiZwYEIu4uMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157837200/-PsVCKGoj5wYEJCrvMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11157773599/MFVtCMXhjJwYEJ-6uMgp',
                ...options.params
            })
            gtag('event', 'conversion', {
                'send_to': 'AW-11127768621/qSiJCMy8vq4YEK2Mkbop',
                ...options.params
            })
            if(page_host.includes('au.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209454013/5QxkCPSKh6kYEL3jiuEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227339976/b5TsCJzlva4YEMi5zukp',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.uk')) {
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11209475132/99k8CKv0kakYELyIjOEp',
                //     ...options.params
                // });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128002358/J3UlCNKFta4YELaun7op',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.de')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209464365/9x5nCKr0h6kYEK20i-Ep',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128041469/67xsCMrlv64YEP3fobop',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128140797/Al5dCJn1os8YEP3np7op',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.fr')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209494545/OPWjCKmXgKkYEJGgjeEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227223104/7KOUCMz9qq4YEMCox-kp',
                    ...options.params
                });
            }
            if(page_host.includes('yfn.es')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209466507/BYh3CLaMlqkYEIvFi-Ep',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11128018717/hNxDCLzCv64YEJ2uoLop',
                    ...options.params
                });
            }
            if(page_host.includes('pt.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209437223/fnV4CIH5i6kYEKfgieEp',
                    ...options.params
                });
            }
            if(page_host.includes('nz.yfn')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11211816115/Cw1QCPORjKkYELP5muIp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11213940102/pU-DCKySta4YEIbLnOMp',
                    ...options.params
                });
            }
            if(page_host.includes('ca.yfn')) {
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11211868232/4HjgCLa3hKkYEMiQnuIp',
                //     ...options.params
                // });
                // gtag('event', 'conversion', {
                //     'send_to': 'AW-11213935986/9dD9CMeEvq4YEPKqnOMp',
                //     ...options.params
                // });
                //广告数据加入dataLayer
                window.dataLayer?.push({
                    'ads_tid': options.params.transaction_id, //订单id
                    'ads_value': options.params.value, //价格
                    'ads_currency': options.params.currency, //价格单位
                    'buy_email': options.params.email
                });
                //GTM广告事件调用
                gtag('event', 'ads-purchase');
            }
            if(page_host.includes('yfn.ae')) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-11209451613/_8j-CNnKlqkYEN3QiuEp',
                    ...options.params
                });
                gtag('event', 'conversion', {
                    'send_to': 'AW-11227340735/UbWzCP-Gq64YEL-_zukp',
                    ...options.params
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    // google 评论
    googleComment(options) {
        try {
            const page_host = window.location.host;
            if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) {
                return
            }
            let merchantId = page_host.includes('ca.yfn.com') ? 5053425018 : page_host.includes('au.yfn.com') ? 758457458 : page_host.includes('yfn.uk') ? 755581933 : page_host.includes('yfn.de') ? 732112767 : 508520869;
            window.renderOptIn = function() {
                window.gapi.load('surveyoptin', function() {
                window.gapi.surveyoptin.render(
                    {
                    // REQUIRED FIELDS
                    "merchant_id": merchantId,
                    "order_id": options.originOrderId,
                    "email": options.buyerEmail,
                    "delivery_country": options.marketplaceId,
                    "estimated_delivery_date": options.time,
                    // OPTIONAL FIELDS
                    "products": options.items
                    });
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    // fbq 埋点
    fbq(options = {}, ctx) {
        try {
            const page_host = window.location.host;
            if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) return
            
            let eventID = options.eventID || new Date().getTime();
            let sendParams = {};
            let email = ctx.$storage.get('email') || '';
            switch(options.type) {
                case 'Search':
                    fbq('track', 'Search', options.params, {eventID: eventID})
                    break;
                case 'AddToWishlist':
                    fbq('track', 'AddToWishlist', options.params, {eventID: eventID})
                    break;
                case 'CompleteRegistration':
                    fbq('track', 'CompleteRegistration', options.params, {eventID: eventID})
                    break;
                case 'AddToCart':
                    fbq('track', 'AddToCart', options.params, {eventID: eventID})
                    break;
                case 'AddPaymentInfo':
                    fbq('track', 'AddPaymentInfo', options.params, {eventID: eventID})
                    break;
                case 'InitiateCheckout':
                    fbq('track', 'InitiateCheckout', options.params, {eventID: eventID})
                    break;
                case 'Purchase':
                    fbq('track', 'Purchase', options.params, {eventID: eventID})
                    break;
                case 'ViewContent':
                    try {
                        fbq('track', 'ViewContent', options.params, {eventID: eventID})
                    } catch (error) {
                        setTimeout(()=>{
                            this.fbq(options, ctx)
                        },2000)
                    }
                    break;
                default:
            }
            // fb 转化Api
            sendParams = {
                event_name: options.type,
                event_time: new Date().getTime(),
                event_id: eventID,
                event_source_url: location.href,
                custom_data: options.params,
            }
            if(email) {
                sendParams.user_data = {};
                try {
                    sendParams.user_data.em = SHA256(email);                
                } catch (error) {}
            }
            send.fbq(sendParams, ctx)
        } catch (error) {
            console.log(error)
        }
    }
    // Awin 埋点
    awin(options) {
        try {
            const page_host = window.location.host;
            if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) {
                return
            }
            AWIN.Tracking = {};
            AWIN.Tracking.Sale = {};
            /*** Set your transaction parameters ***/
            AWIN.Tracking.Sale.amount = options.amount;
            AWIN.Tracking.Sale.orderRef = options.orderId;
            AWIN.Tracking.Sale.parts = `default:${options.amount}`;
            AWIN.Tracking.Sale.voucher = '';
            AWIN.Tracking.Sale.currency = 'USD';
            AWIN.Tracking.Sale.channel = 'awin';
            AWIN.Tracking.Sale.test = '0';
            // Fallback Image Pixel Tracking - REQUIRED
            let tag = document.querySelector('body');
            if(options.tag) {
                tag.appendChild(options.tag)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // shareasale
    shareSale(options) {
        let tag = document.querySelector('body');
        if(options.tag) {
            tag.appendChild(options.tag)
        }
    }
    // Criteo 数据埋点
    criteo(options) {
        // const page_host = window.location.host;
        // if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) {
        //     return
        // }
        // window.criteo_q = window.criteo_q || [];
        // let deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        // window.criteo_q.push(
        //     { event: "setSiteType", type: deviceType}
        // );
        // switch(options.type) {
        //     case 'viewPage':
        //         // <!-- Criteo Visit Tag -->
        //         window.criteo_q.push(
        //             { event: "viewPage" }
        //         );
        //         // <!-- END Criteo Visit Tag -->
        //         break;
        //     case 'viewHome':
        //         // <!-- Criteo Homepage Tag -->
        //         window.criteo_q.push(
        //             { event: "viewHome" }
        //         );
        //         // <!-- END Criteo Homepage Tag -->
        //         break;
        //     case 'viewItem':
        //         // <!-- Criteo Product Tag -->
        //         window.criteo_q.push(
        //             { event: "viewItem", item: options.params}
        //         );
        //         // <!-- END Criteo Product Tag -->
        //         break;
        //     case 'addToCart':
        //         // <!-- Criteo Add to Cart Tag -->
        //         window.criteo_q.push(
        //             { event: "addToCart", item: options.params}
        //         );
        //         // <!-- END Criteo Add to Cart Tag -->
        //         break;
        //     case 'viewBasket':
        //         // <!-- Criteo Basket / Cart Tag -->
        //         window.criteo_q.push(
        //             { event: "viewBasket", item: options.params}
        //         );
        //         // <!-- END Criteo Basket / Cart Tag -->
        //         break;
        //     case 'viewList':
        //         // <!-- Criteo Listing Tag -->
        //         window.criteo_q.push(
        //             { event: "viewList", ...options.params}
        //         );
        //         // <!-- END Criteo Listing Tag -->
        //         break;
        //     case 'trackTransaction':
        //         window.criteo_q.push(
        //             { event: "trackTransaction", id: options.id, item: options.params}
        //         );
        //         break;
        //     default:
        // }
    }
    // tiktok 埋点
    tiktok(options) {
        // return
        try {
            const page_host = window.location.host;
            if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) {
                return
            }
            switch(options.type) {
                case 'ViewContent':
                    ttq.track('ViewContent', options.params)
                    break;
                case 'Search':
                    ttq.track('Search', options.params)
                    break;
                case 'AddToCart':
                    ttq.track('AddToCart', options.params)
                    break;
                case 'InitiateCheckout':
                    ttq.track('InitiateCheckout', options.params)
                    break;
                case 'PlaceAnOrder':
                    ttq.track('PlaceAnOrder', options.params)
                    break;
                case 'CompletePayment':
                    ttq.track('CompletePayment', options.params)
                    break;
                case 'CompleteRegistration':
                    ttq.track('CompleteRegistration')
                    break;
                case 'ClickButton':
                    ttq.track('ClickButton', options.params)
                    break;
                case 'AddPaymentInfo':
                    ttq.track('AddPaymentInfo', options.params)
                    break;
                case 'AddToWishlist':
                    ttq.track('AddToWishlist')
                    break;
                case 'SubmitForm':
                    ttq.track('SubmitForm')
                    break;
                case 'Contact':
                    ttq.track('Contact')
                    break;
                case 'Subscribe':
                    ttq.track('Subscribe')
                    break;
                default:
            }
        } catch (error) {
            console.log(error)
        }
    }
    // mitgo 埋点
    mitgo(options) {
        return
        const page_host = window.location.host;
        if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) {
            return
        }
        let admitadUser = () => {
            ADMITAD = window.ADMITAD || {};
            ADMITAD.Invoice = ADMITAD.Invoice || {};
            ADMITAD.Invoice.accountId = options.params.email;
        };
        let admitadPurchase = () => {
            ADMITAD = window.ADMITAD || {};
            ADMITAD.Invoice = ADMITAD.Invoice || {};
            ADMITAD.Invoice.category = '1';
            ADMITAD.Invoice.broker = 'adm';
            ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];
            ADMITAD.Invoice.referencesOrder.push({
                orderNumber: options.params.orderNumber,
                // discountCode: 'AD1524',
                orderedItem: options.params.orderedItem
            });
            ADMITAD.Tracking.processPositions()
        };
        let buriedFn = () => {
            switch(options.type) {
                case 'user_data':
                    admitadUser()
                    break;
                case 'purchase':
                    admitadPurchase()
                    break;
                default:
            }
        };
        let i = 0;
        let clear = setInterval(() => {
            i  += 1
            try {
                if(ADMITAD) {
                    buriedFn()
                    clearInterval(clear)
                }
            } catch {}
            i >= 30 && clearInterval(clear)
        }, 100)
    }
    // bing
    bing(options) {
        return
        const page_host = window.location.host;
        if(['mmall.dev.selleroa.com', 'mmall.preview.selleroa.com'].includes(page_host) || page_host.includes('10.10')) {
            return
        }
        switch(options.type) {
            case 'purchase':
                window.uetq = window.uetq || [];
                window.uetq.push('event', 'purchase', options.params);
            break;
            default:
        }
    }
    // goaffpro
    goaffpro(options) {
        try {
            goaffproTrackConversion(options.params);
        } catch {}
    }
}

export default Fn