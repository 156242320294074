/**
 * @description 设置接口请求头
 *
 * */
import System from '/utils/system'
import Channel from '/utils/channel'

const system = new System()
const channel = new Channel()

class Fn {
    /*cookie -> country、currency、language、userId、deviceId*/
    async setCountryLocal(app){
        if (process.client) {
        } 
    }
    // 设置token
    setToken(app) {
        const cooToken = app.$cookies.get('token') || '';
        this.token = app.$storage.get('token') || cooToken
    }
    // GMT
    setTimeZone() {
        this.timeZone = "GMT" + new Date().getTimezoneOffset() / 60
    }
    // 国家code
    setCountry(app) {
        let c = {};
        if(process.client){
            c = app.store.state.country.cryList.find((res)=>{
                return res.mRealmName == location.origin
            })
        }
        const storeCountry = app.store.state.national.country?.id?app.store.state.national.country : c;
        let country = app.$storage.get('country')
        this.country =  country.id||country.countryCode?country:storeCountry
    }
    // 货币currency
    setCurrency(app) {
        let c = {};
        if(process.client){
            let res = app.store.state.country.cryList.find((res)=>{
                return res.mRealmName == location.origin
            })
            c = res?{code: res.currencyCode,symbol: res.currencySymbol}:{}
        }
        const storeCurrency = app.store.state.national.currency?.code ? app.store.state.national.currency : c;
        this.currency = app.$storage.get('currency') || storeCurrency
    }
    // 国际语言
    setLang(app) {
        let c = {};
        if(process.client){
            let res = app.store.state.country.cryList.find((res)=>{
                return res.mRealmName == location.origin
            })
            c = res?{identify: res.language,langCode: res.langCode,language: res.languageName}:{}
        }
        const storeLang = app.store.state.national.language?.identify ? app.store.state.national.language : c;
        this.language = app.$storage.get('language') || storeLang
    }
    // 用户userId
    setUserId(app) {
        const cooUserId = app.$cookies.get('userId') || '';
        this.userId = app.$storage.get('userId') || cooUserId
    }
    // 营销渠道
    setChannel(app) {
        this.channel = {
            pid: '',
            pidChannel: '',
            strUrm: ''
        }
        if(process.client) {
            this.channel.pid = channel.ditch(app).pid
            this.channel.pidChannel = channel.ditch(app).pidChannel
            this.channel.strUrm = channel.utm(app).strUrm || ''
        }
    }
    // spm用户追踪体系
    setSpm(app) {
        this.spm = app.$storage.get('spm') || ''
    }
    // 设置设备号
    setDeviceId(app) {
        const storeDeviceId = app.store.state.national.deviceId || '';
        this.deviceId = app.$cookies.get('deviceId') || app.$storage.get('deviceId') || storeDeviceId || '';
        let deviceId = app.$storage.get('deviceId')
        if(!app.$cookies.get('deviceId') && process.client && deviceId){
            app.$cookies.set('deviceId',deviceId)
        }
    }
    // 埋点id
    setTraceId(app) {
        this.app = app
        let traceId = '';
        if(process.client) {
            traceId = sessionStorage.getItem('ubrKey')
            if(!traceId) {
                let ubrKey = new Date().getTime();
                sessionStorage.setItem('ubrKey', ubrKey)
                traceId = ubrKey
            }
        }
        this.traceId = traceId
    }
    // 外链
    setExternalLink(app) {
        let r = '';
        let referrer = '';
        let pageUrl = '';
        let rsReferrer = '';
        if(process.client) {
            referrer = document.referrer
            r = app.$storage.get('externalLink') || '';
            if(referrer && !referrer.includes('.yfn.') && !referrer.includes('10.10.10')) {
                r = window.btoa(referrer)
                app.$storage.set('externalLink', r)
            }
            pageUrl = location.href ? window.btoa(location.href) : ''
            rsReferrer = document.referrer ? window.btoa(document.referrer) : ''
        }
        this.externalLink = r
        this.rsReferrer = rsReferrer
        this.pageUrl = pageUrl
    }
    // 设置设备信息
    setClient() {
        let def = {
            ver: '0.1',                // 版本
            channel: 'H5',             // 站点来源
            deviceid: '',              // 设备id
            model: '',                 // 设备
            userAgent: '',             // 浏览器版本
            vendor: '',                // 浏览器
            network: '',               // 网络状态
            deviceSystem: '',          // 操作系统版本
            language: '',              // 语言
        };
        def.deviceid = this.deviceId
        this.clientInfo = {str: ''}
        if(process.client) {
            def.model = system.model().mobile
            def.userAgent = system.model().browserVersion
            def.vendor = system.model().browser
            def.network = system.network()
            def.deviceSystem = system.model().osVersion
            def.language = navigator.language
            this.clientInfo.str = window.btoa(JSON.stringify(def))
        }else{
            this.clientInfo.str = 'eyJ2ZXIiOiIwLjEiLCJjaGFubmVsIjoiSDUiLCJkZXZpY2VpZCI6ImxzMzIyNjEwNDZmYTJkMmZjOWQ5MWQzOTk4ZTcxNWNlNDN0czE2Nzc4MjMzMTIzNjIiLCJtb2RlbCI6ImlQaG9uZSIsInVzZXJBZ2VudCI6MTMuMDMsInZlbmRvciI6IlNhZmFyaSIsIm5ldHdvcmsiOiJjYWJsZSBuZXR3b3JrIiwiZGV2aWNlU3lzdGVtIjoxMy4yMywibGFuZ3VhZ2UiOiJ6aC1DTiJ9'
        }
        this.clientInfo.c = def
    }
    // 设置请求头
    getHeaders(app) {
        this.app = app
        this.headers = {}
        this.setToken(app)
        this.setTimeZone(app)
        this.setDeviceId(app)
        this.setCountry(app)
        this.setCurrency(app)
        this.setUserId(app)
        this.setClient(app)
        this.setLang(app)
        this.setChannel(app)
        this.setTraceId(app)
        this.setExternalLink(app)
        this.setSpm(app)
        // console.log(app.store.$country)
        this.headers['Client-Info'] = this.clientInfo.str || ''
        this.headers['Mall-User-Token'] = this.token
        this.headers['network'] = this.clientInfo.c.network || ''
        this.headers['timeZone'] = this.timeZone
        this.headers['countryCode'] = app.$query.country_code || this.country?.countryCode || ''
        this.headers['language'] = this.language.identify || ''
        this.headers['useCurrencyCode'] = this.currency.code || ''
        this.headers['userId'] = this.userId
        this.headers['traceId'] = this.traceId
        this.headers['pid'] = this.channel.pid || ''
        this.headers['pidChannel'] = this.channel.pidChannel || ''
        this.headers['externalLink'] = this.externalLink
        this.headers['rsReferrer'] = this.rsReferrer
        this.headers['page-url'] = this.pageUrl
        this.headers['utm'] = this.channel.strUrm || ''
        this.headers['spm'] = this.spm
        return this.headers
    }
}

export default Fn